import type { SVGProps } from 'react';

export const HydraLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={149}
    height={71}
    fill='none'
    viewBox='0 0 149 71'
    {...props}
  >
    <g clipPath='url(#hydra-logo_svg__a)'>
      <path
        fill='#fff'
        d='M67.129 49.98V39.547L56.83 26.758h5.765l7.054 8.812 7.136-8.812h5.733L72.26 39.546v10.435h-5.13ZM91.232 26.758c7.846 0 14.145 4.994 14.145 11.61 0 6.617-6.3 11.613-14.145 11.613h-8.487V26.758h8.487Zm-3.395 3.557v16.101h3.054c5.546 0 9.395-3.624 9.395-8.047s-3.85-8.054-9.357-8.054h-3.092ZM51.559 26.758v9.573H38.726v-9.573h-.254a44.746 44.746 0 0 1-4.836 15.296v7.927h5.091V39.929H51.56v10.052h5.093V26.758h-5.093ZM134.978 25.836l-10.321 17.76 3.215 3.63 1.262-2.247h11.732l2.791 4.995h5.361l-14.04-24.138Zm-3.998 15.842 3.998-7.19 4.037 7.19h-8.035Z'
      />
      <path
        fill='#fff'
        d='M112.015 49.98h-5.091V26.759h7.77c6.073 0 10.406 2.64 10.406 7.19 0 3.15-2.075 5.313-5.394 6.36l8.563 9.673h-5.915l-7.884-8.976h-2.452l-.003 8.976Zm0-12.406h2.68c3.13 0 5.243-1.303 5.243-3.626 0-2.354-2.113-3.659-5.243-3.659h-2.68v7.285Z'
      />
      <path
        fill='url(#hydra-logo_svg__b)'
        fillRule='evenodd'
        d='M10.39 32.137v-.005l-.012.008.012-.003Zm-5.063 3.662a43.5 43.5 0 0 1-1.332-4.166c3.873-2.968 10.968-3.088 14.752-.656-3.266-.737-6.097-.188-8.342 1.151.894-.534 1.862-.93 2.87-1.175 0 0-3.103.186-5.518 1.827l-.031.008a.746.746 0 0 0-.11.09c-.978.696-1.828 1.64-2.29 2.921Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__c)'
        fillRule='evenodd'
        d='M10.39 32.137c.899-.538 1.871-.937 2.886-1.184 0 0-6.35.38-7.953 4.857v.016c-.241.83.473 3 .473 3l1.834 2.035c-1.427-5.92 2.347-8.472 2.76-8.729v.005Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__d)'
        fillRule='evenodd'
        d='M12.916 22.717a9.824 9.824 0 0 1-1.195-1.656 4.59 4.59 0 0 0 .393 1.915c.178.416.412.806.695 1.158.353.124.946.346 1.544.558l1.952.712a11 11 0 0 1-1.528-.967 11.813 11.813 0 0 1-1.86-1.72Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__e)'
        fillRule='evenodd'
        d='M9.19 24.383c-.568-.055-.939-.064-.939-.064s.173.204.36.434c.107.14.214.287.315.445.703-.142 2.96-.278 2.96-.278a12.591 12.591 0 0 0-2.697-.537Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__f)'
        fillRule='evenodd'
        d='M4.73 27.363s.435-.126.982-.267c.492-.124 1.072-.26 1.49-.319 0 0-.39-.108-.946-.2-.63-.103-1.485-.184-2.207-.055.267.245.496.528.681.841Z'
        clipRule='evenodd'
      />
      <path fill='#270C59' d='M22.263 24.303h-.001l.001.001v-.001Z' />
      <path
        fill='url(#hydra-logo_svg__g)'
        d='M19.148 52.73a10.342 10.342 0 0 1-3.416-1.29 11.757 11.757 0 0 1-4.45-4.934c-4.909-9.977 4.023-17.184 13.378-13.083h.015s-1.303.319-2.172.971c1.871-.336 3.582-.092 5.045.303 0 0 .864-2.018 1.025-2.544.161-.527-.236-1.546-.531-2.089a2.472 2.472 0 0 1-.167.753l-1.735-1.144c.428-1.704-3.014-1.64-3.888-5.375 0 .128-.072 1.934 1.003 2.803l-.987-.56-.014-.038c-.076-.029-2.996-1.149-4.888-2.553 1.29 1.585 3.041 1.942 2.838 3.268l1.734 1.144a2.48 2.48 0 0 0 .168-.755c.294.544.69 1.564.53 2.09-.162.527-1.024 2.545-1.024 2.545-1.47-.396-3.18-.638-5.045-.305.868-.655 2.17-.97 2.17-.97-3.265-.738-6.101-.185-8.356 1.16v-.02c-.413.26-4.188 2.816-2.76 8.73l2.134 3.796c.946 1.99 3.34 5.584 4.31 6.562 1.748 1.77 4.504 3.539 4.504 3.539'
      />
      <path
        fill='url(#hydra-logo_svg__h)'
        d='M23.266 27.108c-1.075-.87-1.01-2.676-1.003-2.803a2.914 2.914 0 0 0 .006 2.208l.015.038.982.557Z'
      />
      <path
        fill='url(#hydra-logo_svg__i)'
        d='M14.372 41.736V41.707c-1.78-4.522 1.684-10.37 10.3-8.284-9.354-4.097-18.94 3.033-14.03 13.007.984 2.043 3.045 4.248 4.967 5.424 1.055.63 2.287 1.277 3.551.876a41.642 41.642 0 0 0 4.267-4.244c-.953-.012-1.9-.159-2.813-.434-4.604-1.4-6.137-5.98-6.242-6.316Z'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M-.013 22.088A44.86 44.86 0 0 0 5.06 41.63a44.294 44.294 0 0 0 13.097 15.251c5.464-4 9.944-9.218 13.095-15.251a44.874 44.874 0 0 0 5.072-19.542 38.388 38.388 0 0 0-11.51-4.005c3.276 1.682 6.508 3.745 8.809 5.6l-.02.32a42.162 42.162 0 0 1-4.589 16.238 41.688 41.688 0 0 1-10.566 13.075l-.283.234-.284-.234A41.688 41.688 0 0 1 7.31 40.24a42.16 42.16 0 0 1-4.59-16.242l-.02-.32a25.647 25.647 0 0 1 5.32-4.813A38.879 38.879 0 0 0 0 22.088h-.013Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        d='M20.772 14.28s-.112 1.915 1.002 2.811l-.985-.56v-.012c-1.465-.69-3.392-1.866-4.603-2.998 0 .065-.007.13-.007.196 0-.066 0-.131.008-.197 0 0 .946 2.553 3.732 3.468l-2.645-.38s-2.32-.597-4.558.174c0 0 .87-.032 3.245.515 0 0-1.85.212-2.564.369-.075 0-3.013.124-4.876 1.313l.01.01c1.435-.32 3.4-.141 3.4-.141-.82.223-2.73.983-2.73.983v-.01c-.108.034-2.508.798-4.08 2.604a18.9 18.9 0 0 1 2.491-.886c-.595.442-1.154.93-1.673 1.46 0 0-1.608 1.617-2.89 4.584l.158.414a9.819 9.819 0 0 1 1.528-.651 3.701 3.701 0 0 0-.681-.841c1.155-.737 2.723-1.063 3.766-1.206.26-.192.527-.375.797-.552l-.36-.434a7.231 7.231 0 0 1 1.687-.356 16.484 16.484 0 0 1 2.176-.977 4.567 4.567 0 0 1-.399-1.92 14.426 14.426 0 0 0 1.908 1.469c.845-.213 1.706-.35 2.575-.409v.011c.027-.109.064-.216.11-.319.026.102.055.201.084.299a14.256 14.256 0 0 1 6.78 1.318h.033H23.19c-.14.035-1.34.349-2.157.966 1.872-.336 3.581-.092 5.046.304 0 0 .864-2.019 1.025-2.553.16-.535-.237-1.546-.532-2.089a2.51 2.51 0 0 1-.157.755l-1.735-1.146c.445-1.876-3.11-1.779-3.899-5.415'
      />
      <path
        fill='url(#hydra-logo_svg__j)'
        fillRule='evenodd'
        d='M16.177 13.525c0 .033-.002.066-.004.098-.002.033-.004.066-.004.099 0 .861.366 1.831.86 2.568a4.9 4.9 0 0 0 .236.32l2.644.38c-2.783-.92-3.732-3.465-3.732-3.465Zm5.597 3.566c-1.114-.901-1.002-2.81-1.002-2.81a2.751 2.751 0 0 0-.21 1.165 3.053 3.053 0 0 0 .227 1.077v.01l.985.558ZM5.107 22.42a18.894 18.894 0 0 1 2.505-.88c-.595.442-1.154.93-1.673 1.46a2.153 2.153 0 0 0-.832-.58Zm6.811-3.571s-1.964-.18-3.404.139c.263.242.489.522.67.831v.01s1.915-.757 2.734-.98Zm.789-2.061c.017 0 .89-.027 3.245.515 0 0-1.85.212-2.564.364a8.907 8.907 0 0 0-.681-.88Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__k)'
        fillRule='evenodd'
        d='M23.405 48.588a41.869 41.869 0 0 0 5.302-7.682l-.053-.06c-4.38 6.668-12.586 5.355-14.306.982 0 0 1.468 4.884 6.236 6.337.916.275 1.865.422 2.82.434v-.01Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__l)'
        fillRule='evenodd'
        d='M16.311 21.817a2.936 2.936 0 0 0 .016 2.246l.987.559c-1.116-.9-1.003-2.811-1.003-2.811v.006Z'
        clipRule='evenodd'
      />
      <path
        fill='url(#hydra-logo_svg__m)'
        d='m23.19 23.437-.003-.002h-.006c-2.275-.512-4.34-.434-6.14.075a6.88 6.88 0 0 1-.17-.28l-.024-.044a3.605 3.605 0 0 1-.175-.34 5.732 5.732 0 0 1-.284-.737 14.255 14.255 0 0 1 6.78 1.318l.019.008h.003v.002Z'
      />
      <path
        fill='url(#hydra-logo_svg__n)'
        d='M13.63 22.526h.01c.292.193.58.37.852.533l.012.008.316.18h.009l.148.084.047.027.212.119.094.05.115.064.131.07.062.033.158.08.036.018.003.002c.048.025.092.048.136.069.085-.035.173-.067.261-.1a2.854 2.854 0 0 1-.061-.239l-.014-.078c-.01-.05-.02-.1-.026-.15l-.005-.044-.006-.042a4.9 4.9 0 0 1-.01-.137.392.392 0 0 1-.001-.057.33.33 0 0 0 0-.021v-.369c0-.03 0-.06.01-.09.004-.013.005-.026.005-.039 0-.014 0-.027.006-.041.008-.02.008-.038.01-.057v-.025a.535.535 0 0 1 .014-.065l.004-.018.009-.04.005-.027.002-.013c.003-.019.012-.05.019-.076v-.001l.006-.023V22.1c-.867.065-1.727.207-2.57.426Z'
      />
      <path
        fill='url(#hydra-logo_svg__o)'
        d='M9.933 23.963h.002c.7-.377 1.426-.703 2.173-.975a4.908 4.908 0 0 0 .69 1.152h-.01a9.398 9.398 0 0 0-2.853-.177l-.003.002.001-.002Z'
      />
      <path
        fill='url(#hydra-logo_svg__p)'
        d='M8.934 25.198s-.456.016-1.12.107c.26-.191.527-.375.804-.552l.02.027.031.042c.086.117.18.243.265.376Z'
      />
    </g>
    <defs>
      <linearGradient
        id='hydra-logo_svg__b'
        x1={3.626}
        x2={14.194}
        y1={32.07}
        y2={30.823}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__c'
        x1={5.203}
        x2={10.472}
        y1={39.25}
        y2={33.728}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__d'
        x1={2172.19}
        x2={2305.53}
        y1={653.701}
        y2={653.701}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__e'
        x1={1641.27}
        x2={1725.19}
        y1={160.732}
        y2={160.732}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__f'
        x1={1337.01}
        x2={1400.08}
        y1={179.49}
        y2={179.49}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__g'
        x1={10092.6}
        x2={14168.9}
        y1={4520.83}
        y2={10341}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__h'
        x1={23.156}
        x2={21.185}
        y1={26.47}
        y2={24.2}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__i'
        x1={15.983}
        x2={18.704}
        y1={33.331}
        y2={42.837}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' stopOpacity={0.1} />
        <stop offset={0.9} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__j'
        x1={13.441}
        x2={15.739}
        y1={13.525}
        y2={22.324}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__k'
        x1={17.36}
        x2={25.22}
        y1={44.646}
        y2={49.746}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__l'
        x1={16.098}
        x2={17.314}
        y1={23.216}
        y2={23.216}
        gradientUnits='userSpaceOnUse'
      >
        <stop offset={0.39} stopColor='#fff' />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__m'
        x1={12.929}
        x2={18.387}
        y1={18.668}
        y2={28.345}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__n'
        x1={12.929}
        x2={18.387}
        y1={18.668}
        y2={28.345}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__o'
        x1={12.929}
        x2={18.387}
        y1={18.668}
        y2={28.345}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='hydra-logo_svg__p'
        x1={12.929}
        x2={18.387}
        y1={18.668}
        y2={28.345}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <clipPath id='hydra-logo_svg__a'>
        <path fill='#fff' d='M0 0h149v71H0z' />
      </clipPath>
    </defs>
  </svg>
);
